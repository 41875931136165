<template>
	<v-menu offset-y min-width="100px" style="padding: 0px 4px" rounded bottom v-if="textinput">
		<template v-slot:activator="{ on, attrs }">
			<div v-bind="attrs" v-on="on">
				<v-chip class="ml-1 mb-1 pl-2 pr-1 text-capitalize" :color="`${color} white--text`" small>
					{{ textinput }}
					<v-icon style="font-size: 22px" small>mdi-menu-down</v-icon>
				</v-chip>
			</div>
		</template>

		<v-list elevation>
			<v-list-item
				class="list-item cursor-pointer p-1"
				v-for="(item, index) in items"
				:key="index"
				@click="updateStatus(item)"
			>
				<v-list-item-title class="pl-4">{{ (item.text && item.text) || "" }}</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>
<script>
export default {
	name: "text-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: [String, Number],
			default: null,
		},
		items: {
			type: [Array],
			default: () => {
				return [];
			},
		},
		dense: {
			type: Boolean,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			textinput: null,
			color: "orange",
		};
	},
	watch: {
		value() {
			// this.textinput = this.value;
			let found = this.items.find((item) => item.value == this.value);
			this.textinput = found && found.text ? found.text : this.value;
			this.setDefault();
		},
		textinput() {
			this.$emit("input", this.textinput);
		},
		items() {
			this.setDefault();
		},
	},
	methods: {
		updateStatus(status) {
			this.textinput = status.value;
			this.color = status.status_color;
			this.$emit("change", this.textinput);
			this.$emit("change-with-text", status);
		},
		setDefault() {
			this.$nextTick(() => {
				const _status = this.items.find((row) => row.value == this.value);
				if (_status) {
					this.textinput = _status.text ? _status.text : "";
					this.color = _status.status_color;
					//this.$emit("change", this.textinput);
				}
			});
		},
	},
	mounted() {
		this.setDefault();
	},
};
</script>
